import React, { useState } from "react"
import phone from "../../logo/phone.svg"
import IconButton from '@mui/material/IconButton';
import { Typography, Box } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input'
import CustomButton from "../../Component/button"
import { useNavigate } from 'react-router-dom';
import { getRequest, postRequest } from "../../api/services";
import SnackbarComponent from '../../Component/snackbarCom';


import './verifyOTP.scss'


function VerifyOTP() {
    const navigate = useNavigate()
    const [is_loading, setIs_loading] = useState<boolean>(false)
    const [otp, setOtp] = useState<any>('')
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarStatus, setSnackbarStatus] = useState(false);

    const handleChange = (newValue: any) => {
        setOtp(newValue)
    }

    const handleVerify = async () => {
        setIs_loading(true)
        try {
            const req = {
                OTP: otp,
            }
            const url = "user/verify-otp"
            const token = localStorage.getItem('token') ?? ""
            const response = await postRequest(url, req, token);
            setSnackbarStatus(response.status)
            if (response.status) {
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                setOtp('')
                navigate("/submit-details/upload-section")
                setIs_loading(false)
            } else {
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                setIs_loading(false)
            }

        } catch (error: any) {
            setSnackbarMessage("Error");
            setSnackbarOpen(true);
            setIs_loading(false)
        }
    }
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const resendOTP = async () => {
        try {
            const url = "user/resend-otp"
            const token = localStorage.getItem('token') ?? ""
            const response = await getRequest(url, token);
            setSnackbarStatus(response.status)
            if (response.status) {
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                setOtp('')
                setIs_loading(false)
            } else {
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                setIs_loading(false)
            }

        } catch (error: any) {
            setSnackbarMessage("Error");
            setSnackbarOpen(true);
            setIs_loading(false)
        }
    }

    return (

        <div className="verify_page">
            <Box className="component" sx={{
                width: {
                    xs: '90%', // Small screens
                    lg: '25%'  // Large screens
                },
            }}>
                <IconButton edge="start" sx={{ background: "#0000000A", marginLeft: '1px', padding: '30px' }}>
                    <img
                        src={phone}  // Replace with the path to your image
                        alt="icon"

                    />
                </IconButton>
                <Typography
                    variant="h1"

                >
                    Verify Phone Number
                </Typography>
                <div className="text_otp">
                    <Typography
                        variant="h3"

                    >
                        Enter the 6 digit OTP received
                    </Typography>
                    <Typography
                        variant="h3"

                    >
                        on your phone number
                    </Typography>
                </div>
                <MuiOtpInput length={6} value={otp} onChange={handleChange} />
                <Typography
                    variant="h1"
                    sx={{
                        textAlign: 'center',
                        width: '100%'
                    }}
                >
                    <Typography
                        variant="h4"
                    >
                        Didn't receive a code?
                    </Typography>
                    &nbsp; {/* Non-breaking space for spacing */}
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#1976d2', // Optional: Color for the "Sign Up" text
                            cursor: 'pointer' // Optional: Cursor pointer for the "Sign Up" text
                        }}
                        onClick={resendOTP}
                    >
                        Resend
                    </Typography>
                </Typography>
                <CustomButton variants="contained" loading={is_loading} disabled={false} label="Verify" onClickFunction={handleVerify} />
            </Box>
            <SnackbarComponent
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={handleCloseSnackbar}
                status={snackbarStatus}
            />
        </div>




    )
}

export default VerifyOTP