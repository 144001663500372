export const convertImagesToBlob = async (imageURL: any, file: any) => {

    try {
        const response = await fetch(imageURL);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const blob = await response.blob();
        const reUploadableFile = new File([blob], file.name, { type: file.type });
        // Create a new preview URL from the re-uploadable file
        const newPreviewUrl = URL.createObjectURL(reUploadableFile);
        return newPreviewUrl
    } catch (error) {
        console.error("Failed to fetch image:", error);
        // throw error; // Rethrow the error if necessary
    }
}