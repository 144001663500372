import React from 'react';
import { Progress } from 'antd';
import type { ProgressProps } from 'antd';

interface CustomProgressProps {
    value: number; // Percentage value for the Progress component
}

const CustomProgressWithSettings: React.FC<CustomProgressProps> = ({ value }) => {
    return (
        < >

            <Progress
                type="dashboard"
                steps={8}
                percent={value}
                trailColor="rgba(0, 0, 0, 0.06)"
                strokeWidth={20}
                size='small'
            // strokeColor={conicColors}
            />



        </>
    );
};

export default CustomProgressWithSettings;
