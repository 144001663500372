import { useState, useEffect } from "react"
import { Typography, Box } from '@mui/material';
import SelectInputCustome from "../../../Component/selectInput"
import { useNavigate, useLocation } from 'react-router-dom';
import CustomButton from "../../../Component/button";
import SnackbarComponent from '../../../Component/snackbarCom';
import { postRequest, getRequest } from "../../../api/services";
import { useDispatch, useSelector } from 'react-redux';



import "./aboutMe.scss"
const aboutMeformInit = {
    i_am: {
        type: "Select",
        label: "I identify as",
        value: "",
        message: "",
        required: true,
        error: false,
        options: [{ key: "Male", value: "Male", }, { key: "Female", value: "Female" }, { key: "Non-Binary", value: "Non-Binary" }]
    },
    looking_for: {
        type: "Select",
        value: "",
        label: "I am looking for a",
        message: "",
        required: true,
        error: false,
        options: [{ key: "Male", value: "Male", }, { key: "Female", value: "Female" }, { key: "Any", value: "Any" }]
    },



}


function AboutMe() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();
    const isEditPresent = location.pathname.includes('edit');
    const UserInfo = useSelector((state: { user: any }) => state?.user.UserInfo);
    const [aboutMeForm, setAboutMeForm] = useState<any>(aboutMeformInit)
    const [is_loading, setIs_loading] = useState<boolean>(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarStatus, setSnackbarStatus] = useState(false);

    const validateForm = (form: any) => {
        const updatedForm = { ...form };

        Object.keys(updatedForm).forEach((key) => {
            const field = updatedForm[key];
            if (field.required && field.value.trim() === "") {
                field.error = true;
                field.message = `Please select ${field.label.toLowerCase()}.`;
            } else {
                field.error = false;
                field.message = "";
            }
        });

        return updatedForm;
    };

    const handleLogin = async () => {
        setIs_loading(true)
        try {
            const validatedForm = validateForm(aboutMeForm);
            setAboutMeForm(validatedForm);

            const hasErrors = Object.values(validatedForm).some((field: any) => field.error);
            if (hasErrors) {
                return;
            }
            const req = {
                I_am_a: aboutMeForm['i_am'].value,
                looking_for: aboutMeForm['looking_for'].value
            }
            const url = "user/looking-for"
            const token = localStorage.getItem('token') ?? ""

            const response = await postRequest(url, req, token);
            setSnackbarStatus(response.status)
            if (response.status) {
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                setAboutMeForm(aboutMeformInit)
                if (!isEditPresent) {
                    navigate("/submit-details/about-your-self");
                }
                navigate("/submit-details/about-your-self/edit");



            } else {
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                setIs_loading(false)
            }

        } catch (error: any) {
            setSnackbarMessage("Error");
            setSnackbarOpen(true);
            setIs_loading(false)
        }

    }
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (UserInfo?.is_about_me_1_completed) {
            setAboutMeForm({
                ...aboutMeformInit,
                i_am: {
                    ...aboutMeformInit['i_am'],
                    value: UserInfo?.user_details?.I_am_a,
                },
                looking_for: {
                    ...aboutMeformInit['looking_for'],
                    value: UserInfo?.user_details?.looking_for,
                }
            })
        }
    }, [UserInfo?.is_about_me_1_completed]);

    return (

        <Box className="about_me_page" sx={{
            justifyContent: {
                xs: 'space-around',
                md: 'center'
            }
        }} >
            <Box className="Textual_information">
                <Typography
                    variant="h1"
                >
                    About Me
                </Typography>
                <Typography
                    variant="h3"
                >
                    Tell us what you are looking for
                </Typography>
            </Box>

            <Box
                component="form"
                noValidate
                autoComplete="off"
                className="form_section"
            >
                {Object.keys(aboutMeForm).map((key: string, ind: number) => {
                    return (
                        <div key={ind} style={{ width: "100%" }}>
                            <SelectInputCustome
                                message={aboutMeForm[key].message}
                                value={aboutMeForm[key].value}
                                label={aboutMeForm[key].label}
                                options={aboutMeForm[key].options}
                                error={aboutMeForm[key].error}
                                onChangeFunction={(e: any) => {
                                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    if (aboutMeForm[key].type === "email" && !emailRegex.test(e.target.value)) {
                                        setAboutMeForm({
                                            ...aboutMeForm,
                                            [key]: {
                                                ...aboutMeForm[key],
                                                value: e.target.value,
                                                error: true,
                                                message: `Please enter a valid ${key} address.`
                                            }
                                        })
                                    } else {
                                        setAboutMeForm({
                                            ...aboutMeForm,
                                            [key]: {
                                                ...aboutMeForm[key],
                                                value: e.target.value,
                                                error: false,
                                                message: ""
                                            }
                                        })
                                    }
                                }} />
                        </div>
                    )
                })}


            </Box>
            <CustomButton variants="contained" loading={is_loading} disabled={false} label="Next" onClickFunction={handleLogin} />
            <SnackbarComponent
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={handleCloseSnackbar}
                status={snackbarStatus}
            />

        </Box>

    )
}

export default AboutMe