import { useEffect, useState, useCallback } from "react";
import logo from "../../logo/logo.svg";
import user from "../../logo/user.svg";
import lock from "../../logo/lock.svg";
import { Typography, Box } from "@mui/material";
import InputWithIcon from "../../Component/Input_with_icon";
import CustomButton from "../../Component/button";
import Button from "@mui/material/Button";
import google from "../../logo/google.svg";
import apple from "../../logo/apple.svg";
// import Stickbar from "../../Component/stickbar"
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../api/services";
import SnackbarComponent from "../../Component/snackbarCom";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { getProfile } from "../../utlis";
import { updateUsers } from "../../Reducer/State/StepsReducer";
import { updateChatuser } from "../../Reducer/State/ChatingUser";
import { disconnectSocket } from "../../utlis/socket";
import { validateForm } from "../../utlis/validation";
import debounce from "lodash/debounce";
import AppleSignin from "react-apple-signin-auth";
import { jwtDecode } from "jwt-decode"; // Explicitly importing the default export
import BetaVersionLogo from "../../logo/betaVersion";

import "./login.scss";
const loginformInit = {
  phone: {
    image: user,
    type: "number",
    placeholder: "Enter your phone number",
    value: "",
    message: "",
    required: true,
    error: false,
  },
  password: {
    image: lock,
    type: "password",
    value: "",
    placeholder: "Password",
    message: "",
    required: true,
    error: false,
  },
};
function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginForm, setLoginForm] = useState<any>(loginformInit);
  const [is_loading, setIs_loading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState(false);

  useEffect(() => {
    dispatch(updateUsers({}));
    dispatch(updateChatuser({}));
    disconnectSocket();
  }, []);

  const handleLogin = async () => {
    const validatedForm = validateForm(loginForm);
    setLoginForm(validatedForm);

    const hasErrors = Object.values(validatedForm).some(
      (field: any) => field.error
    );
    console.log(hasErrors);
    if (hasErrors) {
      return;
    }
    setIs_loading(true);
    try {
      const req = {
        phone_number: loginForm["phone"].value,
        password: loginForm["password"].value,
      };
      const url = "user/login";
      const token = localStorage.getItem("token") ?? "";

      const response = await postRequest(url, req, token);
      setSnackbarStatus(response.status);
      if (response.status) {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        localStorage.setItem("token", response.token);
        setLoginForm(loginformInit);
        getProfile(navigate, dispatch);
      } else {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        setIs_loading(false);
      }
    } catch (error: any) {
      setSnackbarMessage("Error");
      setSnackbarOpen(true);
      setIs_loading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const LoginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const req = {
        accessToken: tokenResponse.access_token,
        clientId:
          "824563392946-gm5qpi6pts31ibi6r642lovjoop01o3e.apps.googleusercontent.com",
      };
      const url = "user/google-auth";
      const token = "";
      const response = await postRequest(url, req, token);
      setSnackbarStatus(response.status);
      if (response.status) {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        localStorage.setItem("token", response.token);
        getProfile(navigate, dispatch);
      } else {
        setSnackbarMessage(response.error);
        setSnackbarOpen(true);
      }
    },
    scope: "https://www.googleapis.com/auth/youtube.readonly",
  });

  const LoginWithApple = async (idToken: any) => {
    if (idToken) {
      const decodedToken = jwtDecode<AppleIdToken>(idToken);
      const req = {
        firstName: decodedToken.given_name, // Apple's ID token uses 'given_name' for the first name
        lastName: decodedToken.family_name, // 'family_name' for the last name
        idToken: idToken,
      };
      const url = "user/apple-auth";
      const token = "";
      const response = await postRequest(url, req, token);
      setSnackbarStatus(response.status);
      if (response.status) {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        localStorage.setItem("token", response.token);
        getProfile(navigate, dispatch);
      } else {
        setSnackbarMessage(response.error);
        setSnackbarOpen(true);
      }
    }
  };

  const debouncedPasswordChange = debounce((value: string) => {
    // Check password criteria
    console.log("hit");
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    let error = false;
    let message = "";

    // Set error message if any criteria is not met
    if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSymbol) {
      error = true;
      message =
        "Password must contain an uppercase letter, a lowercase letter, a number, and a symbol.";
    }

    // Update login form state
    setLoginForm((prevForm: any) => ({
      ...prevForm,
      password: {
        ...prevForm.password,
        // value: value,
        error: error,
        message: message,
      },
    }));
  }, 1000);

  const handleChange = (e: any, key: string) => {
    setLoginForm({
      ...loginForm,
      [key]: {
        ...loginForm[key],
        value: e,
        error: false,
        message: "",
      },
    });
    if (key === "password") {
      debouncedPasswordChange(e);
    }
  };

  interface AppleIdToken {
    given_name?: string; // 'given_name' for the first name (optional)
    family_name?: string; // 'family_name' for the last name (optional)
    sub: string; // 'sub' is the unique identifier for the user
    // Add more fields if needed
  }

  return (
    <>
      <div className="login_page">
        <Box
          sx={{
            width: {
              xs: "60%", // Small screens
              lg: "14%", // Large screens
            },
            height: {
              xs: "30%", // Small screens
              lg: "30%", // Large screens
            },
          }}
          className="Box"
        >
          <BetaVersionLogo />
          {/* <img
                        src={logo}
                        alt="logo"
                    /> */}
        </Box>
        <Box
          className="form"
          sx={{
            height: "100%",
            width: {
              xs: "100%",
              md: "70%",
            },
            padding: {
              xs: `30px 0px`,
              md: "30px 0px",
            },
          }}
        >
          <Typography variant="h1">Welcome</Typography>
          <Box
            sx={{
              height: "100%",
              width: {
                xs: "90%", // Small screens
                lg: "45%", // Large screens
              },
            }}
            className="login_component"
          >
            <Box
              component="form"
              noValidate
              autoComplete="off"
              className="form_section"
            >
              {Object.keys(loginForm).map((key: string, ind: number) => {
                return (
                  <div key={ind} style={{ width: "100%" }}>
                    <InputWithIcon
                      imageSrc={loginForm[key].image}
                      placeholder={loginForm[key].placeholder}
                      typeOfinput={loginForm[key].type}
                      message={loginForm[key].message}
                      value={loginForm[key].value}
                      required={loginForm[key].required}
                      error={loginForm[key].error}
                      onChangeFunction={(e: any) => handleChange(e, key)}
                    />
                  </div>
                );
              })}
              <Typography
                variant="h3"
                sx={{
                  textAlign: "end",
                  width: "100%",
                }}
              >
                Forget password
              </Typography>
            </Box>
            <CustomButton
              variants="contained"
              loading={is_loading}
              label="Login"
              disabled={false}
              onClickFunction={handleLogin}
            />
            <Typography
              variant="h1"
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography variant="h4">Don't have an account?</Typography>
              &nbsp; {/* Non-breaking space for spacing */}
              <Typography
                variant="h4"
                sx={{
                  color: "#1976d2", // Optional: Color for the "Sign Up" text
                  cursor: "pointer", // Optional: Cursor pointer for the "Sign Up" text
                  fontWeight: "600",
                }}
                onClick={() => navigate("/signup")}
              >
                Sign Up
              </Typography>
            </Typography>
            <Box
              className="button_components"
              sx={{
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                gap: "20px",
              }}
            >
              <AppleSignin
                /** Auth options passed to AppleID.auth.init() */
                authOptions={{
                  /** Client ID - eg: 'com.example.com' */
                  clientId: "d8r.me",
                  /** Requested scopes, seperated by spaces - eg: 'email name' */
                  scope: "email name",
                  /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                  redirectURI: "https://my.d8r.me/",
                  /** State string that is returned with the apple response */
                  state: "state",
                  /** Nonce */
                  nonce: "nonce",
                  /** Uses popup auth instead of redirection */
                  usePopup: true,
                }} // REQUIRED
                /** General props */
                uiType="dark"
                /** className */
                className="apple-auth-btn"
                /** Removes default style tag */
                noDefaultStyle={false}
                /** Allows to change the button's children, eg: for changing the button text */
                buttonExtraChildren="Continue with Apple"
                /** Extra controlling props */
                /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                onSuccess={(response: any) => {
                  if (response?.authorization?.id_token) {
                    LoginWithApple(response?.authorization?.id_token);
                  }
                }} // default = undefined
                /** Called upon signin error */
                onError={(error: any) => console.error(error)} // default = undefined
                /** Skips loading the apple script if true */
                skipScript={false} // default = undefined
                /** Apple image props */
                iconProps={{ style: { marginTop: "10px" } }} // default = undefined
                /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                render={(props: any) => (
                  <Button
                    variant="outlined"
                    {...props}
                    sx={{
                      width: {
                        xs: "100%",
                        md: "45%",
                        lg: "45%",
                      },
                    }}
                  >
                    <img
                      src={apple} // Replace with the actual path to the Google logo
                      alt="Apple logo"
                      className="logo" // Adjust size and spacing
                    />
                    <h1 style={{ fontSize: "12px" }}>
                      {" "}
                      Login with <b>Apple</b>{" "}
                    </h1>
                  </Button>
                )}
              />
              <Button
                variant="outlined"
                sx={{
                  width: {
                    xs: "100%",
                    md: "45%",
                    lg: "45%",
                  },
                }}
                onClick={() => LoginWithGoogle()}
              >
                <img
                  src={google} // Replace with the actual path to the Google logo
                  alt="Google logo"
                  className="logo"
                />
                <h1 style={{ fontSize: "12px" }}>
                  {" "}
                  Login with <strong>Google</strong>{" "}
                </h1>
              </Button>
            </Box>
            <Box
              sx={{
                marginTop: "10px",
              }}
              className="Privacy-component"
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#1976d2", // Optional: Color for the "Sign Up" text
                  cursor: "pointer", // Optional: Cursor pointer for the "Sign Up" text
                  fontWeight: "600",
                }}
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        status={snackbarStatus}
      />
    </>
  );
}

export default Login;
