export const validateForm = (form: any) => {
    const updatedForm = { ...form };

    Object.keys(updatedForm).forEach((key) => {
        const field = updatedForm[key];
        if (field.required && field.value.trim() === "") {
            field.error = true;
            field.message = field.label ? `Please select ${field.label.toLowerCase()}.` : `Please enter ${field.placeholder.toLowerCase()}.`;
        } else {
            field.error = updatedForm[key].error;
            field.message = updatedForm[key].message;
        }
    });

    return updatedForm;
};