import React, { useState, useEffect } from "react";
import logo from "../../logo/logo.svg";
import user from "../../logo/user.svg";
import lock from "../../logo/lock.svg";
import dob from "../../logo/dob.svg";
import phone from "../../logo/phone.svg";
import { Typography, Box } from "@mui/material";
import InputWithIcon from "../../Component/Input_with_icon";
import CustomButton from "../../Component/button";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../api/services";
import moment from "moment";
import SnackbarComponent from "../../Component/snackbarCom";
import { disconnectSocket } from "../../utlis/socket";
import { validateForm } from "../../utlis/validation";
import { getProfile } from "../../utlis";
import { useDispatch } from "react-redux";
import Verify_Loader from "../../Component/verify_loader";
import debounce from "lodash/debounce";

import "./signUp.scss";
import BetaVersionLogo from "../../logo/betaVersion";

const singUpformInit = {
  first_name: {
    image: user,
    type: "text",
    placeholder: "First Name",
    value: "",
    message: "",
    required: true,
    error: false,
  },
  last_name: {
    image: user,
    type: "text",
    value: "",
    placeholder: "Last Name",
    message: "",
    required: true,
    error: false,
  },
  date_of_birth: {
    image: dob,
    type: "date",
    value: "",
    placeholder: "Date of Birth",
    message: "",
    required: true,
    error: false,
  },
  phone_number: {
    image: phone,
    type: "number",
    value: "",
    placeholder: "Phone Number",
    message: "",
    required: true,
    error: false,
  },
  password: {
    image: lock,
    type: "password",
    value: "",
    placeholder: "Password",
    message: "",
    required: true,
    error: false,
  },
  confirm_password: {
    image: lock,
    type: "password",
    value: "",
    placeholder: "Confirm Password",
    message: "",
    required: true,
    error: false,
  },
};
function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signUpForm, setSignUpForm] = useState<any>(singUpformInit);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState(false);

  useEffect(() => {
    disconnectSocket();
  }, []);
  const formatDate = (dateString: any) => {
    return moment(dateString).format("DD/MM/YYYY");
  };

  const handleSignUp = async () => {
    const validatedForm = validateForm(signUpForm);
    setSignUpForm(validatedForm);

    if (signUpForm.password !== signUpForm.confirm_password) {
      setSignUpForm((prevForm: any) => ({
        ...prevForm,
        confirm_password: {
          ...prevForm.confirm_password,
          // value: value,
          error: true,
          message: "Password is not matching.",
        },
      }));
      return;
    }

    const hasErrors = Object.values(validatedForm).some(
      (field: any) => field.error
    );
    if (hasErrors) {
      return;
    }

    setIsLoading(true);
    const req = {
      first_name: signUpForm["first_name"].value,
      last_name: signUpForm["last_name"].value,
      date_of_birth: formatDate(signUpForm["date_of_birth"].value),
      phone_number: signUpForm["phone_number"].value,
      password: signUpForm["password"].value,
    };
    const url = "user/sign-up";
    const token = "";

    const response = await postRequest(url, req, token);
    setSnackbarStatus(response.status);
    if (response.status) {
      setSnackbarMessage(response.message);
      setSnackbarOpen(true);
      setSignUpForm(singUpformInit);
      localStorage.setItem("token", response.token);
      getProfile(navigate, dispatch);
      setIsLoading(false);
    } else {
      setSnackbarMessage(response.message);
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const debouncedPasswordChange = debounce((value: string) => {
    // Check password criteria
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    let error = false;
    let message = "";

    // Set error message if any criteria is not met
    if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSymbol) {
      error = true;
      message =
        "Password must contain an uppercase letter, a lowercase letter, a number, and a symbol.";
    }

    // Update login form state
    setSignUpForm((prevForm: any) => ({
      ...prevForm,
      password: {
        ...prevForm.password,
        // value: value,
        error: error,
        message: message,
      },
    }));
  }, 1000);

  const debouncedPasswordMatch = debounce((value: string) => {
    if (value !== signUpForm["password"].value) {
      setSignUpForm((prevForm: any) => ({
        ...prevForm,
        confirm_password: {
          ...prevForm.confirm_password,
          // value: value,
          error: true,
          message: "Password is not matching.",
        },
      }));
    } else {
      setSignUpForm((prevForm: any) => ({
        ...prevForm,
        confirm_password: {
          ...prevForm.confirm_password,
          // value: value,
          error: false,
          message: "",
        },
      }));
    }
  }, 1000);

  const handleChange = (e: any, key: string) => {
    setSignUpForm({
      ...signUpForm,
      [key]: {
        ...signUpForm[key],
        value: e,
        error: false,
        message: "",
      },
    });
    console.log(key, e);
    if (key === "password") {
      debouncedPasswordChange(e);
    }
    if (key === "confirm_password") {
      debouncedPasswordMatch(e);
    }
  };

  return (
    <>
      {isLoading ? (
        <Verify_Loader />
      ) : (
        <div className="signUp_page">
          <Box
            sx={{
              width: {
                xs: "60%", // Small screens
                lg: "14%", // Large screens
              },
              height: {
                xs: "30%", // Small screens
                lg: "30%", // Large screens
              },
            }}
            className="Box"
          >
            {/* <img
                        src={logo}
                        alt="logo"
                    /> */}
            <BetaVersionLogo />
          </Box>
          <Box
            className="form"
            sx={{
              width: {
                xs: "100%",
                md: "70%",
              },
              padding: {
                xs: `30px 0px`,
                md: "30px 0px",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "90%", // Small screens
                  lg: "45%", // Large screens
                },
              }}
              className="signUp_component"
            >
              <Typography variant="h1">Create an Account</Typography>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                className="form_section"
              >
                {Object.keys(signUpForm).map((key: string, ind: number) => {
                  return (
                    <div key={ind} style={{ width: "100%" }}>
                      <InputWithIcon
                        imageSrc={signUpForm[key].image}
                        placeholder={signUpForm[key].placeholder}
                        typeOfinput={signUpForm[key].type}
                        message={signUpForm[key].message}
                        value={signUpForm[key].value}
                        required={signUpForm[key].required}
                        error={signUpForm[key].error}
                        onChangeFunction={(e: any) => handleChange(e, key)}
                      />
                    </div>
                  );
                })}
              </Box>
              <CustomButton
                variants="contained"
                loading={isLoading}
                label="Join Now"
                disabled={false}
                onClickFunction={handleSignUp}
              />

              <Typography
                variant="h1"
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Typography
                  // variant="span" // Use span to keep inline
                  sx={{
                    display: "inline",
                    fontSize: {
                      xs: "15px", // Small screens
                      lg: "18px", // Large screens
                    },
                    fontWeight: "600",
                  }}
                >
                  Already a User?
                </Typography>
                &nbsp; {/* Non-breaking space for spacing */}
                <Typography
                  // variant="span" // Use span to keep inline
                  sx={{
                    display: "inline",
                    fontSize: {
                      xs: "15px", // Small screens
                      lg: "18px", // Large screens
                    },
                    fontWeight: "600",
                    color: "#1976d2", // Optional: Color for the "Sign Up" text
                    cursor: "pointer", // Optional: Cursor pointer for the "Sign Up" text
                  }}
                  onClick={() => navigate("/")}
                >
                  Login
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "10px",
              }}
              className="Privacy-component"
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#1976d2", // Optional: Color for the "Sign Up" text
                  cursor: "pointer", // Optional: Cursor pointer for the "Sign Up" text
                  fontWeight: "600",
                }}
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </Typography>
            </Box>
          </Box>
        </div>
      )}

      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        status={snackbarStatus}
      />
    </>
  );
}

export default Signup;
