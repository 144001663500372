import React from "react"
import Loader from "../Component/loader"
import { Typography, Box } from '@mui/material';

import './component.scss'
function Verify_Loader() {
    return (

        <div className="verify_laoder">
            <Loader size={100} />
            <Typography
                variant="h1"

            >
                Verify Phone Number...
            </Typography>
            <Typography
                variant="h3"

            >
                it may take few seconds
            </Typography>
        </div>


    )
}

export default Verify_Loader