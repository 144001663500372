import react, { useEffect, useState } from "react";
import { Box, IconButton, Avatar, Typography, Drawer } from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import MenuIcon from "@mui/icons-material/Menu";
import navbar from "../logo/navbar.svg";
import { Outlet } from "react-router-dom";
import avatar from "../logo/avatar.svg";
import home from "../logo/home.svg";
import messages from "../logo/chat.svg";
import setting from "../logo/setting.svg";
import "./layout.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDrawer } from "../Reducer/State/drawerState";

const PageLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserInfo = useSelector((state: { user: any }) => state?.user.UserInfo);
  const drawerOpen = useSelector(
    (state: { drawerSidebar: any }) => state?.drawerSidebar.drawer
  );
  const [sideBarMenu, setSideBarMenu] = useState<any>([
    { title: "Matches", Icon: home, active: true },
    { title: "Messages", Icon: messages, active: false },
    { title: "Settings", Icon: setting, active: false },
  ]);
  const showScreen = (name: any) => {
    const updatedMenu = sideBarMenu.map((item: any) => ({
      ...item,
      active: item.title === name,
    }));

    // Set the updated menu state
    setSideBarMenu(updatedMenu);
    if (name === "Matches") {
      navigate("/profile/matches");
    }
    if (name === "Messages") {
      navigate("/profile/chats");
    }
    if (name === "Settings") {
      navigate("/profile/setting");
    }
  };

  const handleDrawerToggle = () => {
    dispatch(updateDrawer(!drawerOpen));
  };

  useEffect(() => {
    const handleLocationChange = () => {
      if (window.location.href.includes("chats")) {
        const updatedMenu = sideBarMenu.map((item: any) => ({
          ...item,
          active: item.title === "Messages",
        }));
        setSideBarMenu(updatedMenu);
      }
      if (window.location.href.includes("matches")) {
        const updatedMenu = sideBarMenu.map((item: any) => ({
          ...item,
          active: item.title === "Matches",
        }));
        setSideBarMenu(updatedMenu);
      }
      if (window.location.href.includes("setting")) {
        const updatedMenu = sideBarMenu.map((item: any) => ({
          ...item,
          active: item.title === "Settings",
        }));
        setSideBarMenu(updatedMenu);
      }
    };

    // Listen for popstate (back/forward buttons)
    window.addEventListener("popstate", handleLocationChange);

    // Listen for URL changes via pushState/replaceState (modern SPA behavior)
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(this, args);
      handleLocationChange();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      handleLocationChange();
    };

    return () => {
      // Clean up the event listeners
      window.removeEventListener("popstate", handleLocationChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  useEffect(() => {
    const updateVh = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    };

    window.addEventListener("resize", updateVh);
    updateVh(); // Set initial value

    return () => window.removeEventListener("resize", updateVh);
  }, []);

  const Avator = UserInfo?.images ? UserInfo?.images[0] : avatar;
  // console.log(Avator)
  return (
    <>
      <Box
        className="Layout"
        sx={{
          gap: {
            xs: "0",
            md: "15px",
          },
        }}
      >
        <Box
          className="SideBar"
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <IconButton className="avatar">
            <Avatar alt="Remy Sharp" src={Avator} className="image" />
          </IconButton>
          <Box className="SideBar-Menu">
            {sideBarMenu.map((x: any, ind: any) => (
              <IconButton
                className={x.active ? "Menu-Icon active" : "Menu-Icon"}
                onClick={() => showScreen(x.title)}
                key={ind}
              >
                <div>
                  <img src={x.Icon} alt="Icon" />
                  <Typography variant="h5">{x.title}</Typography>
                </div>
              </IconButton>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
          }}
        >
          <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
            {/* Content inside the drawer */}
            <Box className="SideBar">
              {/* Close icon inside the drawer */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {/* <IconButton onClick={handleDrawerToggle}>
                                    <BackspaceIcon />
                                </IconButton> */}

                {/* Avatar */}
                <IconButton className="avatar">
                  <Avatar alt="Remy Sharp" src={Avator} className="image" />
                </IconButton>
              </Box>

              {/* SideBar Menu */}
              <Box className="SideBar-Menu">
                {sideBarMenu.map((x: any, ind: any) => (
                  <IconButton
                    className={x.active ? "Menu-Icon active" : "Menu-Icon"}
                    onClick={() => showScreen(x.title)}
                    key={ind}
                  >
                    <div>
                      <img src={x.Icon} alt="Icon" />
                      <Typography variant="h5">{x.title}</Typography>
                    </div>
                  </IconButton>
                ))}
              </Box>
            </Box>
          </Drawer>
        </Box>
        <Box className="MainScreen">
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default PageLayout;
