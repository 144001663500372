import { io } from 'socket.io-client';

// Function to get the token, replace with your actual logic
const getToken = () => localStorage.getItem('token') ?? '';

export const initializeSocket = () => {

    const URL = "https://backend.d8r.me/";


    const socket = io(URL, {
        auth: {
            token: getToken(),
        },
        transports: ['websocket'], // Ensure WebSocket transport is used
        query: {
            token: getToken(),
        },
        autoConnect: false, // Prevents automatic connection
    });

    return socket;
};