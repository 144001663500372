import React from "react";
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography, Box } from '@mui/material';


interface InputWithImageProps {
    message: string;
    value: string;
    onChangeFunction: any;
    label: any;
    options: any;
    error: any;
}




const SelectInputCustome: React.FC<InputWithImageProps> = ({
    message = "",
    value = "",
    label,
    onChangeFunction,
    options, error }) => {

    return (
        <Box className="SelectInput">
            <Typography
                variant="h2"
            >
                {label}
            </Typography>
            <FormControl sx={{ m: 0, minWidth: 120, width: '100%' }} error={error} >
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={value}
                    onChange={onChangeFunction}
                    sx={{
                        borderRadius: '15px',

                        '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: '15px',

                        },
                    }}
                >
                    {options?.map((item: any) => {
                        return (<MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>)
                    })}

                </Select>
                <FormHelperText sx={{ color: error ? 'red' : 'inherit' }}>{message}</FormHelperText>
            </FormControl>
        </Box>

    )
};

export default SelectInputCustome;
