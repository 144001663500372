import axios from "axios";

const serverHost = "https://backend.d8r.me/api/";
// const serverHost = "http://localhost:5001/api/";

export const getRequest = async (url: string, token: string) => {
  try {
    const response = await axios.get(serverHost + url, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "*/*",
      },
    });
    return response.data;
  } catch (err: any) {
    return (
      err.response?.data || // Prefer detailed server response data, if available
      err.error || // Otherwise, return custom error message if it exists
      err.message
    );
  }
};
// console.log("Testing")
export const postRequest = async (url: string, data: any, token: string) => {
  try {
    // Check if the data is an instance of FormData
    const isFormData = data instanceof FormData;

    const response = await axios.post(serverHost + url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "*/*",
        // Set Content-Type to 'multipart/form-data' if the data is FormData, otherwise 'application/json'
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      },
    });
    return response.data;
  } catch (err: any) {
    return (
      err.response?.data || // Prefer detailed server response data, if available
      err.error || // Otherwise, return custom error message if it exists
      err.message
    );
  }
};
export const patchRequest = async (
  url: string,
  token: string,
  data: any = {}
) => {
  try {
    const response = await axios.patch(
      serverHost + url, // URL
      data, // Body of the PATCH request, if any
      {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "*/*",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err: any) {
    return (
      err.response?.data || // Prefer detailed server response data, if available
      err.error || // Otherwise, return custom error message if it exists
      err.message
    );
  }
};

export const putRequest = async (url: string, data: any, token: string) => {
  try {
    const response = await axios.put(serverHost + url, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "*/*",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err: any) {
    return (
      err.response?.data || // Prefer detailed server response data, if available
      err.error || // Otherwise, return custom error message if it exists
      err.message
    );
  }
};

export const uploadRequest = async (url: string, data: any, token: string) => {
  try {
    const formData = new FormData();
    if (data.length > 0) {
      data.forEach((item: any) => {
        formData.append("files", item);
      });
    } else {
      formData.append("file", data);
    }

    const response = await axios.post(serverHost + url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "*/*",
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (err: any) {
    return (
      err.response?.data || // Prefer detailed server response data, if available
      err.error || // Otherwise, return custom error message if it exists
      err.message
    );
  }
};

export const deleteRequest = async (url: string, data: any, token: string) => {
  try {
    const response = await axios.delete(serverHost + url, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "*/*",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (err: any) {
    return (
      err.response?.data || // Prefer detailed server response data, if available
      err.error || // Otherwise, return custom error message if it exists
      err.message
    );
  }
};
