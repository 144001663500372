import React from "react";
import logo from "./logo.svg";
import betaLogo from "./beta.png";
import { Box } from "@mui/material";

const BetaVersionLogo = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: " 100%", // To allow absolute positioning within the Box
      }}
    >
      {/* Main Logo */}
      <img
        src={logo}
        alt="logo"
        style={{
          height: " 100%",
        }}
      />

      {/* Beta Logo positioned at bottom-right corner */}
      <img
        src={betaLogo}
        alt="beta version"
        style={{
          position: "absolute",
          bottom: "0px",
          right: 0,
          width: "30%", // Adjust size as needed
          height: "auto",
        }}
      />
    </Box>
  );
};

export default BetaVersionLogo;
