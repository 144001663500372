import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import "./uploadAlbums.scss";
import UploadFileInput from "../../../Component/uploadfileInput";
import CustomButton from "../../../Component/button";
import { postRequest } from "../../../api/services";
import SnackbarComponent from "../../../Component/snackbarCom";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { convertImagesToBlob } from "../../../utlis/imageBlob";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ImageFormInit = {
  image1: {
    value: "",
    blob: "",
    name: "image1",
  },
  image2: {
    value: "",
    blob: "",
    name: "image2",
  },
  image3: {
    value: "",
    blob: "",
    name: "image3",
  },
  image4: {
    value: "",
    blob: "",
    name: "image4",
  },
  image5: {
    value: "",
    blob: "",
    name: "image5",
  },
  image6: {
    value: "",
    blob: "",
    name: "image6",
  },
};

const UploadAlbums: React.FC = () => {
  const [imagelist, setImagelist] = useState<any>(ImageFormInit);
  const [is_loading, setIs_loading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState(false);
  const UserInfo = useSelector((state: { user: any }) => state?.user.UserInfo);
  const navigate = useNavigate();
  const location = useLocation();
  const isEditPresent = location.pathname.includes("edit");

  //   const handleSetImage = (key: string, imageURL: any, blob: any) => {
  //     setImagelist((prevImagelist: any) => ({
  //       ...prevImagelist,
  //       [key]: {
  //         value: imageURL,
  //         blob: blob,
  //       },
  //     }));
  //   };

  const handleUploadImages = async () => {
    setIs_loading(true);
    try {
      const req: any = {
        images: [
          imagelist["image1"].value,
          imagelist["image2"].value,
          imagelist["image3"].value,
          imagelist["image4"].value,
          imagelist["image5"].value,
          imagelist["image6"].value,
        ].filter((url) => url && url.trim() !== ""),
      };

      const url = "user/save-all-images";
      const token = localStorage.getItem("token") ?? "";
      const response = await postRequest(url, req, token);
      setSnackbarStatus(response.status);
      if (response.status) {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        setImagelist(ImageFormInit);
        if (!isEditPresent) {
          navigate("/submit-details/looking-for");
        }
        navigate("/submit-details/looking-for/edit");
      } else {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        setIs_loading(false);
      }
    } catch (error) {
      setSnackbarMessage("Error");
      setSnackbarOpen(true);
      setIs_loading(false);
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const getImages = async () => {
      if (UserInfo?.is_images_uploaded) {
        const newImageList = { ...ImageFormInit }; // Create a copy of the initial state

        UserInfo?.images.forEach((imageUrl: any, index: any) => {
          // Check if the index is within the valid range
          if (index < 6) {
            const imageKey = `image${index + 1}` as keyof typeof newImageList;
            newImageList[imageKey].value = imageUrl;
            newImageList[imageKey].blob = imageUrl;
          }
        });

        setImagelist(newImageList);
      }
    };

    getImages();
  }, [UserInfo?.is_images_uploaded]);

  const onDragEnd = (result: any) => {
    console.log(imagelist);
    const { destination, source } = result;

    // If no destination or if the item was dropped in the same place, do nothing
    if (!destination || destination.index === source.index) {
      return;
    }

    // Reorder logic
    const keys = Object.keys(imagelist);
    const [removedKey] = keys.splice(source.index, 1);
    keys.splice(destination.index, 0, removedKey);

    // Rebuild the object with the new order
    const reorderedImagelist = keys.reduce((acc: any, key: any) => {
      acc[key] = imagelist[key];
      return acc;
    }, {});

    setImagelist(reorderedImagelist);
  };

  return (
    <Box
      className="uploadAlbums_page"
      sx={{
        justifyContent: {
          xs: "space-around",
          md: "center",
        },
      }}
    >
      <Typography variant="h1">Upload your photos</Typography>
      <div className="text_center">
        <Typography variant="h3">Please upload at least 3 photos,</Typography>
        <Typography variant="h3">and a maximum of 6 photos</Typography>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="uploadList" direction="horizontal">
          {(provided: any) => (
            <div
              className="Upload_container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {Object.keys(imagelist).map((key: string, index: number) => (
                <Draggable
                  key={imagelist[key].name}
                  draggableId={imagelist[key].name}
                  index={index}
                >
                  {(provided: any) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <UploadFileInput
                        upload={() => {}} // Replace with your actual upload handler
                        name={imagelist[key].name}
                        value={imagelist[key].blob}
                        setState={setImagelist}
                        state={imagelist}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="text_center">
        <Typography variant="h3">Rearrange the photos by dragging</Typography>
        <Typography variant="h3">them in your desired order.</Typography>
      </div>
      <CustomButton
        variants="contained"
        loading={is_loading}
        disabled={false}
        label="Next"
        onClickFunction={handleUploadImages}
      />

      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        status={snackbarStatus}
      />
    </Box>
  );
};

export default UploadAlbums;
