import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


interface InputWithImageProps {
    imageSrc: string; // Path to the SVG file
    placeholder?: string;
    typeOfinput: string;
    message: string;
    value: string;
    required: boolean;
    error: boolean;
    onChangeFunction: any;
}




const InputWithIcon: React.FC<InputWithImageProps> = ({ imageSrc,
    placeholder = "Enter text here",
    typeOfinput = "text",
    message = "",
    value = "",
    required,
    error,
    onChangeFunction }) => {
    const [showPassword, setShowPassword] = useState(false);

    // Function to handle toggle of password visibility
    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword: boolean) => !prevShowPassword);
    };

    return (
        <TextField
            id="outlined-basic"
            type={typeOfinput === "password" && showPassword ? "text" : (typeOfinput === "number" ? "text" : typeOfinput)} // Toggle between "text" and "password"
            value={value}
            onChange={(e) => {
                if (typeOfinput === "number") {
                    const inputValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                    if (inputValue.length <= 12) {
                        onChangeFunction(inputValue); // Call the onChange function with the cleaned value
                    }
                } else {
                    onChangeFunction(e.target.value);
                }
            }}
            required={required}
            fullWidth
            error={error}
            sx={{
                borderRadius: '15px',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '15px',
                }
            }}
            helperText={error ? message : ""}
            variant="outlined"
            placeholder={placeholder}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton edge="start" sx={{ background: "#0000000A", marginLeft: '1px' }}>
                            <img
                                src={imageSrc}
                                alt="icon"
                                style={{
                                    height: "25px",
                                    width: "25px"
                                }}
                            />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: typeOfinput === "password" ? (
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            onClick={handleTogglePasswordVisibility}
                            sx={{ marginRight: '1px' }}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ) : null,
                inputProps: {
                    inputMode: typeOfinput === "number" ? "numeric" : "text", // Optimize keyboard based on input type
                    pattern: typeOfinput === "number" ? "[0-9]*" : undefined, // Pattern to accept only numbers
                },
            }}
        />

    )
};

export default InputWithIcon;
