import { combineReducers } from 'redux';
import StepsReducer from './State/StepsReducer';
import UserReducer from "./State/ChatingUser"
import DrawerReducer from "./State/drawerState"

const rootReducer = combineReducers({
    user: StepsReducer,
    anotherUser: UserReducer,
    drawerSidebar: DrawerReducer,
});

export default rootReducer;