import { useState, useEffect, useRef } from "react";
import { Typography, Box } from "@mui/material";
import TextFieldCustom from "../../../Component/textInput";
import { useNavigate, useLocation } from "react-router-dom";
import CustomButton from "../../../Component/button";
import TextAreaCustom from "../../../Component/textArea";
import CustomScrollbar from "../../../Component/customScrollbar";
import SnackbarComponent from "../../../Component/snackbarCom";
import { postRequest, getRequest } from "../../../api/services";
import { useDispatch, useSelector } from "react-redux";
// import { updateSteps, } from "../../../Reducer/State/StepsReducer";

import "./aboutMe2.scss";
const aboutMeformInit = {
  work: {
    type: "text",
    label: "Where do you work?",
    value: "",
    message: "",
    required: true,
    error: false,
  },
  job_title: {
    type: "text",
    label: "What is your Job Title?",
    value: "",
    message: "",
    required: true,
    error: false,
  },
  school: {
    type: "text",
    label: "Where do you/did you go to school",
    value: "",
    message: "",
    required: true,
    error: false,
  },
  education: {
    type: "text",
    label: "What's your highest completed education level?",
    value: "",
    message: "",
    required: true,
    error: false,
  },
  religious: {
    type: "text",
    label: "What are your religious beliefs?",
    value: "",
    message: "",
    required: true,
    error: false,
  },
  languages: {
    type: "text",
    label: "Do you speak any languages besides English?",
    value: "",
    message: "",
    required: true,
    error: false,
  },
  politics: {
    type: "text",
    label: "What are your politics?",
    value: "",
    message: "",
    required: true,
    error: false,
  },
  alcohol: {
    type: "text",
    label: "Do you drink alcohol?",
    value: "",
    message: "",
    required: true,
    error: false,
  },
  drugs: {
    type: "text",
    label: "Do you Smoke? Marijuana? Psychedelics? Other Drugs?",
    value: "",
    message: "",
    required: true,
    error: false,
  },
  description: {
    type: "textArea",
    label: "",
    value: "",
    message: "",
    required: false,
    error: false,
  },
  //
};

function AboutMe2() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isEditPresent = location.pathname.includes("edit");
  const UserInfo = useSelector((state: { user: any }) => state?.user.UserInfo);
  const [aboutMeForm, setAboutMeForm] = useState<any>(aboutMeformInit);
  const [is_loading, setIs_loading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState(false);
  const formRef = useRef(null);

  const validateForm = (form: any) => {
    const updatedForm = { ...form };

    Object.keys(updatedForm).forEach((key) => {
      const field = updatedForm[key];
      if (field.required && field.value.trim() === "") {
        field.error = true;
        field.message = `Please enter ${field.label.toLowerCase()}.`;
      } else {
        field.error = false;
        field.message = "";
      }
    });

    return updatedForm;
  };

  const handleNext = async () => {
    try {
      const validatedForm = validateForm(aboutMeForm);
      setAboutMeForm(validatedForm);

      const hasErrors = Object.values(validatedForm).some(
        (field: any) => field.error
      );
      // console.log(hasErrors)
      if (hasErrors) {
        return;
      }
      setIs_loading(true);
      const req = {
        work: aboutMeForm["work"].value,
        job_title: aboutMeForm["job_title"].value,
        school: aboutMeForm["school"].value,
        education_level: aboutMeForm["education"].value,
        religious_believe: aboutMeForm["religious"].value,
        language: aboutMeForm["languages"].value,
        politics: aboutMeForm["politics"].value,
        alcohol: aboutMeForm["alcohol"].value,
        drugs: aboutMeForm["drugs"].value,
        about_yourself: aboutMeForm["description"].value,
      };
      const url = "user/about-your-self";
      const token = localStorage.getItem("token") ?? "";

      const response = await postRequest(url, req, token);
      setSnackbarStatus(response.status);
      if (response.status) {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        setAboutMeForm(aboutMeformInit);
        if (!isEditPresent) {
          navigate("/submit-details/connect-to-social-media");
        }
        navigate("/submit-details/connect-to-social-media/edit");

        // dispatch(updateSteps(3));
      } else {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        setIs_loading(false);
      }
    } catch (error: any) {
      setSnackbarMessage("Error");
      setSnackbarOpen(true);
      setIs_loading(false);
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (UserInfo?.is_about_me_2_completed) {
      if (UserInfo?.is_about_me_2_completed && UserInfo?.about_yourself) {
        setAboutMeForm({
          ...aboutMeformInit,
          work: {
            ...aboutMeformInit["work"],
            value: UserInfo?.about_yourself?.work,
          },
          job_title: {
            ...aboutMeformInit["job_title"],
            value: UserInfo?.about_yourself?.job_title,
          },
          school: {
            ...aboutMeformInit["school"],
            value: UserInfo?.about_yourself?.school,
          },
          education: {
            ...aboutMeformInit["education"],
            value: UserInfo?.about_yourself?.education_level,
          },
          religious: {
            ...aboutMeformInit["religious"],
            value: UserInfo?.about_yourself?.religious_believe,
          },
          languages: {
            ...aboutMeformInit["languages"],
            value: UserInfo?.about_yourself?.language,
          },
          politics: {
            ...aboutMeformInit["politics"],
            value: UserInfo?.about_yourself?.politics,
          },
          alcohol: {
            ...aboutMeformInit["alcohol"],
            value: UserInfo?.about_yourself?.alcohol,
          },
          drugs: {
            ...aboutMeformInit["drugs"],
            value: UserInfo?.about_yourself?.drugs,
          },
          description: {
            ...aboutMeformInit["description"],
            value: UserInfo?.about_yourself?.about_yourself,
          },
        });
      }
    }
  }, [UserInfo?.is_about_me_2_completed]);

  return (
    <Box
      className="About_me2"
      sx={{
        justifyContent: {
          xs: "space-around",
          md: "center",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          textDecoration: "underline",
          color: "blue",
          cursor: "pointer",
          marginBottom: "5px",
        }}
        onClick={() => {
          if (!isEditPresent) {
            navigate("/submit-details/connect-to-social-media");
          }
          navigate("/submit-details/connect-to-social-media/edit");
        }}
      >
        Skip this page
      </Box>
      <div className="about_me_2_page">
        <Box className="Textual_information">
          <Typography variant="h1">About Me</Typography>
          <Typography variant="h3">Tell me about your self</Typography>
        </Box>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className="form_section"
          // ref={formRef}
        >
          {Object.keys(aboutMeForm).map((key: string, ind: number) => {
            return (
              <div key={ind} style={{ width: "100%" }}>
                {aboutMeForm[key].type === "textArea" ? (
                  <TextAreaCustom
                    message={aboutMeForm[key].message}
                    value={aboutMeForm[key].value}
                    label={aboutMeForm[key].label}
                    error={aboutMeForm[key].error}
                    required={aboutMeForm[key].required}
                    onChangeFunction={(e: any) => {
                      setAboutMeForm({
                        ...aboutMeForm,
                        [key]: {
                          ...aboutMeForm[key],
                          value: e.target.value,
                          error: false,
                          message: "",
                        },
                      });
                    }}
                  />
                ) : (
                  <TextFieldCustom
                    message={aboutMeForm[key].message}
                    value={aboutMeForm[key].value}
                    label={aboutMeForm[key].label}
                    error={aboutMeForm[key].error}
                    required={aboutMeForm[key].required}
                    onChangeFunction={(e: any) => {
                      setAboutMeForm({
                        ...aboutMeForm,
                        [key]: {
                          ...aboutMeForm[key],
                          value: e.target.value,
                          error: false,
                          message: "",
                        },
                      });
                    }}
                  />
                )}
              </div>
            );
          })}
        </Box>
        {/* <CustomScrollbar scrollRef={formRef} maxHeight="400px" /> */}
        <CustomButton
          variants="contained"
          loading={is_loading}
          label="Next"
          disabled={false}
          onClickFunction={handleNext}
        />

        <SnackbarComponent
          open={snackbarOpen}
          message={snackbarMessage}
          onClose={handleCloseSnackbar}
          status={snackbarStatus}
        />
      </div>
    </Box>
  );
}

export default AboutMe2;
