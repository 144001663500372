import React from 'react';
import { getRequest } from '../api/services';
import { updateUsers } from "../Reducer/State/StepsReducer";
import { useLocation } from 'react-router-dom';


export const getProfile = async (navigate: any, dispatch: any) => {
    const pathname = window.location.pathname;
    const isEditPresent = pathname.includes('edit');
    try {
        // Check if the current URL is /submit-details and contains a "code" parameter
        const currentPath = window.location.pathname;
        const searchParams = new URLSearchParams(window.location.search);
        const codeParam = searchParams.get("code");

        if (currentPath === "/submit-details" && codeParam) {
            // If the current URL matches the condition, do not navigate away
            const url = "user/profile";
            const token = localStorage.getItem('token') ?? "";
            const response = await getRequest(url, token);
            dispatch(updateUsers(response.data));
            navigate("/submit-details/connect-to-social-media");
            return;
        }

        const url = "user/profile";
        const token = localStorage.getItem('token') ?? "";
        const response = await getRequest(url, token);
        dispatch(updateUsers(response.data));

        if (response.status && !isEditPresent) {
            if (!response.data.is_phone_verified) {
                navigate("/verify-otp");
            } else if (!response.data.is_images_uploaded) {
                navigate("/submit-details/upload-section");
            } else if (!response.data.is_about_me_1_completed) {
                navigate("/submit-details/looking-for");
            }
            // else if (!response.data.is_about_me_2_completed) {
            //     navigate("/submit-details/about-your-self");
            // } 
            else if (!response.data.is_youtube_connected) {
                navigate("/submit-details/connect-to-social-media");
                // } 
                // else if (!response.data.is_spotify_connected) {
                //     navigate("/submit-details/connect-to-social-media");
            } else {
                navigate("/profile/matches");
            }
        }
    } catch (error) {
        console.log(error);
    }
};
