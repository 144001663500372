import React from "react";
import TextField from '@mui/material/TextField';
import { Typography, Box } from '@mui/material';


interface InputWithProps {
    message: string;
    value: string;
    onChangeFunction: any;
    label: any;
    required: any;
    error: any;
}




const TextAreaCustom: React.FC<InputWithProps> = ({
    message = "",
    value = "",
    label,
    onChangeFunction,
    required,
    error
}) => {

    return (
        <Box className="SelectInput">
            <Typography
                variant="h2"
            >
                {label}
            </Typography>
            <TextField
                required={required}
                fullWidth
                error={error}
                id="outlined-required"
                value={value}
                helperText={message}
                onChange={onChangeFunction}
                multiline // Enable multi-line text input
                minRows={4} // Minimum of 4 lines
                sx={{
                    borderRadius: '15px',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '15px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '15px', // Apply borderRadius to the outline
                    }
                }}
            />

        </Box>

    )
};

export default TextAreaCustom;
