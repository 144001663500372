import React from "react";
import Button from '@mui/material/Button';
import "./component.scss"
import CircularProgress from '@mui/material/CircularProgress';

interface buttonWithProps {
    label: any;
    variants: any;
    onClickFunction: any;
    disabled: any;
    loading: any
}

const CustomButton: React.FC<buttonWithProps> = ({
    label,
    variants,
    onClickFunction, disabled, loading }) => (
    <Button variant={variants} onClick={onClickFunction} disabled={disabled} className={variants === "outlined" ? "button_ouline" : "button"}>{loading ? <CircularProgress
        color="inherit" // Ensures the color is inherited or set by the sx prop
        size={30}
        sx={{ color: 'white' }} // Set the color to white
    /> : label}</Button>
);

export default CustomButton;