import { useEffect, useState } from "react";
import navbar from "../../logo/navbar.svg";
import "./findMatch.scss";
import { Box, IconButton, Typography } from "@mui/material";
import heart from "../../logo/heart.svg";
import chats from "../../logo/chats.svg";
import dislike from "../../logo/dislike.svg";
import dummyImage from "../../assest/dummy.png";
import dummyImage2 from "../../assest/dummy2.png";
import dummyImage3 from "../../assest/dummy3.png";
import { getRequest, patchRequest } from "../../api/services";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SnackbarComponent from "../../Component/snackbarCom";
import Loader from "../../Component/loader";
import { updateChatuser } from "../../Reducer/State/ChatingUser";
import { disconnectSocket } from "../../utlis/socket";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { updateDrawer } from "../../Reducer/State/drawerState";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CustomProgressWithSettings from "../../Component/gauge";

const Images = [dummyImage, dummyImage, dummyImage];

const FindMatchesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserInfo = useSelector((state: { user: any }) => state?.user.UserInfo);
  const drawerOpen = useSelector(
    (state: { drawerSidebar: any }) => state?.drawerSidebar.drawer
  );
  const [personDetails, setPersonDetails] = useState<any>({
    date_of_birth: "",
    first_name: "",
    gender: "",
    images: [],
    last_name: "",
    spotify_similarity: 0,
    youtube_similarity: 0,
    _id: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [is_loading, setIs_loading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState(false);

  const servicesScroring = [
    {
      title: "Spotify",
      meter: personDetails?.spotify_similarity
        ? personDetails?.spotify_similarity * 100
        : 0,
    },
    {
      title: "Youtube",
      meter: personDetails?.youtube_similarity
        ? personDetails?.youtube_similarity * 100
        : 0,
    },
  ];
  useEffect(() => {
    disconnectSocket();
    const callOnes = async () => {
      await getMatches();
    };
    callOnes();
  }, []);

  const getMatches = async () => {
    try {
      setIs_loading(true);
      setPersonDetails({
        date_of_birth: "",
        first_name: "",
        gender: "",
        images: [],
        last_name: "",
        spotify_similarity: 0,
        youtube_similarity: 0,
        _id: "",
      });
      const url = "user/matching-result";
      const token = localStorage.getItem("token") ?? "";
      const response = await getRequest(url, token);
      setSnackbarStatus(response.status);
      if (response.status) {
        let spotify_percent = response?.user?.spotify_similarity;
        let youtube_percent = response?.user?.youtube_similarity;
        if (response?.user?.spotify_similarity < 0) {
          spotify_percent = response.user.spotify_similarity * -1;
        }
        if (response?.user?.youtube_similarity < 0) {
          youtube_percent = response.user.youtube_similarity * -1;
        }
        console.log(spotify_percent, youtube_percent);
        setPersonDetails({
          ...personDetails,
          date_of_birth: response.user.date_of_birth,
          first_name: response.user.first_name,
          gender: response.user.gender,
          images: response.user.images,
          last_name: response.user.last_name,
          spotify_similarity: spotify_percent ? spotify_percent?.toFixed(2) : 0,
          youtube_similarity: youtube_percent.toFixed(2),
          _id: response.user._id,
        });
        setIs_loading(false);
      } else {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        setIs_loading(false);
      }
    } catch (error) {
      // console.log(error)
      setIs_loading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // console.log(UserInfo)

  const LikePerson = async () => {
    try {
      const url = `user/like-user/${personDetails._id}`;
      const token = localStorage.getItem("token") ?? "";
      // console.log(token)
      const response = await patchRequest(url, token);
      setSnackbarStatus(response.status);
      if (response.status) {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage(response.error);
        setSnackbarOpen(true);
      }
    } catch (error) {
      // console.log(error)
    }
  };

  const RejectPerson = async () => {
    try {
      const url = `user/reject-user/${personDetails._id}`;
      const token = localStorage.getItem("token") ?? "";
      const response = await patchRequest(url, token);
      if (response.status) {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        getMatches();
      } else {
        setSnackbarMessage(response.error);
        setSnackbarOpen(true);
      }
    } catch (error) {
      // console.log(error)
    }
  };

  const ChatPerson = async () => {
    const url = "user/room-id_user";
    const token = localStorage.getItem("token") ?? "";
    const params = new URLSearchParams({
      matched_id: personDetails._id,
    });
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await getRequest(urlWithParams, token);
    if (response.status) {
      const req = {
        first_name: personDetails.first_name,
        last_name: personDetails.last_name,
        status: personDetails.status,
        _id: personDetails._id,
        room_id: response?.room_id ?? "",
        image: personDetails.images[0],
        isUser: response?.user_id ?? "",
        anotherUser: personDetails._id,
      };
      dispatch(updateChatuser(req));
      navigate(`/profile/chats/${personDetails._id}`);
    }
  };

  const UserDetails = [
    {
      image: dummyImage3,
      title: "Where do you work?",
      description: UserInfo?.about_yourself?.work,
    },
    {
      image: dummyImage2,
      title: "What is your job title?",
      description: UserInfo?.about_yourself?.job_title,
    },
    {
      image: dummyImage2,
      title: "What is your job title?",
      description: UserInfo?.about_yourself?.job_title,
    },
  ];

  const handleDrawerToggle = () => {
    dispatch(updateDrawer(!drawerOpen));
  };

  return (
    <>
      {is_loading ? (
        <div className="Loader_component">
          <Loader size={100} />
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          {personDetails?.first_name ? (
            <div className="Matches_page">
              <Box className="section">
                <IconButton
                  className="avatar"
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                  }}
                >
                  <img src={navbar} alt="logo" />
                </IconButton>
                <IconButton
                  className="avatar"
                  onClick={handleDrawerToggle}
                  sx={{
                    display: {
                      xs: "flex",
                      md: "none",
                    },
                  }}
                >
                  <img src={navbar} alt="logo" />
                </IconButton>
                <Typography variant="h1">
                  Hi, {UserInfo?.first_name} {UserInfo?.last_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    marginRight: {
                      xs: "0px",
                      md: "100px",
                    },
                    fontWeight: 700,
                    color: "#3D64E3",
                    textDecoration: "underline",
                    fontSize: "1.1rem",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                  onClick={getMatches}
                >
                  Next <ArrowForwardIcon />
                </Typography>
              </Box>
              <Box className="section">
                <Typography
                  variant="h1"
                  sx={{ fontWeight: 700, color: "#3D64E3" }}
                >
                  {personDetails.first_name} {personDetails.last_name}
                </Typography>
              </Box>
              <Box className="section">
                {servicesScroring.map((x: any, ind: any) => {
                  return (
                    <Box
                      className="meter-section"
                      key={ind}
                      sx={{
                        flexGrow: {
                          xs: 1,
                          md: 0,
                        },
                        width: {
                          xs: "100%",
                          md: "30%",
                        },
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{ fontWeight: 700, width: "60%" }}
                      >
                        {x.title}
                      </Typography>
                      <Box
                        sx={{
                          width: "40%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <CustomProgressWithSettings
                          value={
                            x.meter > 100 ? 100 : x.meter < 0 ? 0 : x.meter
                          }
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Box className="section-2">
                {personDetails.images.map((x: string, index: number) => (
                  <Box
                    key={index}
                    className="image-container"
                    sx={{
                      flexGrow: {
                        xs: 1,
                        md: 0,
                      },
                      width: {
                        xs: "100%",
                        md: "30%",
                      },
                    }}
                  >
                    <LazyLoadImage
                      alt={x}
                      src={x} // use normal <img> attributes as props
                      effect="blur"
                      wrapperProps={{
                        // If you need to, you can tweak the effect transition using the wrapper style.
                        style: { transitionDelay: "1s", width: "100%" },
                      }}
                      className="Images"
                    />

                    {/* <img src={x} alt="images" className="Images" /> */}
                    <div className="menu-icons">
                      <IconButton className="activity-icon">
                        <img src={chats} alt="logo" onClick={ChatPerson} />
                      </IconButton>
                      <IconButton
                        className="activity-icon-2"
                        onClick={LikePerson}
                      >
                        <img src={heart} alt="logo" />
                      </IconButton>
                      <IconButton
                        className="activity-icon"
                        onClick={RejectPerson}
                      >
                        <img src={dislike} alt="logo" />
                      </IconButton>
                    </div>
                  </Box>
                ))}
              </Box>
              <Box className="section">
                <Typography
                  variant="h5"
                  sx={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  {UserInfo?.about_yourself?.about_yourself ?? ""}
                </Typography>
              </Box>
              <Box className="section-2">
                {UserDetails.map((x: any, ind: any) => (
                  <Box
                    className="job-details"
                    key={ind}
                    sx={{
                      flexGrow: {
                        xs: 1,
                        md: 0,
                      },
                      width: {
                        xs: "100%",
                        md: "30%",
                      },
                    }}
                  >
                    <img src={x.image} alt="images" className="Images" />
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      {x.title}
                    </Typography>
                    <Typography variant="h5">{x.description}</Typography>
                  </Box>
                ))}
              </Box>
            </div>
          ) : (
            <Box className="No_user_Page">
              <Box className="No_user_section">
                <IconButton
                  className="avatar"
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                  }}
                >
                  <img src={navbar} alt="logo" />
                </IconButton>
                <IconButton
                  className="avatar"
                  onClick={handleDrawerToggle}
                  sx={{
                    display: {
                      xs: "flex",
                      md: "none",
                    },
                  }}
                >
                  <img src={navbar} alt="logo" />
                </IconButton>
                <Typography variant="h1">
                  Hi, {UserInfo?.first_name} {UserInfo?.last_name}
                </Typography>
              </Box>
              <div
                className="Loader_component"
                style={{
                  gap: "20px",
                  flexDirection: "column",
                  padding: "16px",
                }}
              >
                <Typography variant="h1">
                  No, User found, please try again.
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: 700,
                    color: "#3D64E3",
                    textDecoration: "underline",
                    fontSize: "1.1rem",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                  onClick={getMatches}
                >
                  Try again
                </Typography>
              </div>
            </Box>
          )}
        </div>
      )}

      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        status={snackbarStatus}
      />
    </>
  );
};

export default FindMatchesPage;
