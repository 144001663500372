import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "./component.scss"; // Import the CSS file for styling
import { postRequest } from "../api/services";
import { IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { convertImagesToBlob } from "../utlis/imageBlob";

interface UploadImageInput {
  upload: any;
  name: any;
  value: any;
  setState: any;
  state: any;
}

const UploadFileInput: React.FC<UploadImageInput> = ({
  upload,
  name,
  value,
  setState,
  state,
}) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [is_loading, setIs_loading] = useState(false);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return;
    // Generate a preview URL for the uploaded image
    const file = acceptedFiles[0];
    const previewUrl = URL.createObjectURL(file);
    setPreview(previewUrl);

    // Prepare the form data
    const formData = new FormData();
    formData.append("file", file); // 'file' is the key for the file in the form data

    const url = "user/upload-image";
    const token = localStorage.getItem("token") ?? "";

    setIs_loading(true);
    // Send the form data via the postRequest function
    const response = await postRequest(url, formData, token);
    let imageURL: any = "";
    let blobURL: any = "";
    if (response.status) {
      imageURL = response.data.Location;
      blobURL = await convertImagesToBlob(imageURL, file);
    }
    setIs_loading(false);
    setState((prevImagelist: any) => ({
      ...prevImagelist,
      [name]: {
        value: imageURL,
        blob: blobURL,
        name: name,
      },
    }));
    upload(name, imageURL, blobURL);
  }, []);

  const handleClosePreview = () => {
    // Reset the state of preview and image
    setPreview(null);
    setIs_loading(false);
    setState((prevImagelist: any) => ({
      ...prevImagelist,
      [name]: {
        value: "",
        blob: "",
        name: name,
      },
    }));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    setPreview(value);
  }, [value]);

  return (
    <div
      {...(!preview ? getRootProps() : {})}
      className={`upload-container ${preview ? "has-preview" : ""} ${
        isDragActive ? "drag-active" : ""
      }`}
    >
      {!preview && <input {...getInputProps()} />}
      {preview ? (
        <div>
          {is_loading ? (
            <CircularProgress />
          ) : (
            <div>
              <img src={preview} alt="Preview" className="preview-image" />
              <IconButton className="close-icon" onClick={handleClosePreview}>
                <CloseIcon style={{ fontSize: "24px", color: "#fff" }} />
              </IconButton>
            </div>
          )}
        </div>
      ) : (
        <IconButton
          edge="start"
          sx={{ background: "#0000000A", marginLeft: "1px" }}
        >
          <CameraAltIcon style={{ fontSize: "50px" }} />
        </IconButton>
      )}
    </div>
  );
};

export default UploadFileInput;
