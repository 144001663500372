import React from "react";
import "./component.scss"
import loader from "../logo/loader.svg"

interface loaderWithSize {
    size: any;

}

const Loader: React.FC<loaderWithSize> = ({
    size }) => (

    <div className="image-loader" style={{ height: `${size}px`, width: `${size}px` }}>
        <img src={loader}  alt="Loading" />
    </div>
);

export default Loader;