// reducers/StepsReducer.ts
export const UPDATE_USER = 'UPDATE_USER';

// Define the state interface
export interface ChatUser { // Export the interface
    ChatUserInfo: any;
}

// Define the initial state
const initialState: ChatUser = {
    ChatUserInfo: {},
};

export const updateChatuser = (data: any) => ({
    type: UPDATE_USER,
    payload: data,
});

// Define the reducer
const UserReducer = (
    state = initialState,
    action: { type: string; payload: any }
): ChatUser => {
    switch (action.type) {
        case UPDATE_USER:
            // console.log(action.payload)
            return {
                ...state,
                ChatUserInfo: action.payload,
            };
        default:
            return state;
    }
};



export default UserReducer;
