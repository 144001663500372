import React, { useEffect, useState } from 'react';
import CustomStepper from "../Component/steppers"
import { Box } from '@mui/material';
import { Outlet } from "react-router-dom"
import { useLocation } from 'react-router-dom'; // Import the useLocation hook
import "./layout.scss"
// Define the steps


const SubmitDetailsForm: React.FC = ({ }) => {
    const [activeStep, setActiveStep] = useState(0);
    const location = useLocation(); // Get the current location object

    const totalSteps = 4;
    const stepsCount = Number(localStorage.getItem('steps'))

    useEffect(() => {
        const currentPath = location.pathname; // Get the current pathname

        // Determine the step based on the current path
        if (currentPath.includes("/submit-details/upload-section")) {
            setActiveStep(0);
        } else if (currentPath.includes("/submit-details/looking-for")) {
            setActiveStep(1);
        } else if (currentPath.includes("/submit-details/about-your-self")) {
            setActiveStep(2);
        } else if (currentPath.includes("/submit-details/connect-to-social-media")) {
            setActiveStep(3);
        }
    }, [location.pathname]);
    return (
        <div className="submitdetails_page" >
            <Box className='component' sx={{
                width: {
                    xs: '90%', // Small screens
                    lg: '40%'  // Large screens
                },
            }}>
                <CustomStepper activeStep={activeStep} totalSteps={totalSteps} />
                <div className='ScrollBar_component'>
                    <Outlet />
                </div>

            </Box>

        </div>
    );
};

export default SubmitDetailsForm;
