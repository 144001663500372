import { Box } from "@mui/material";
import BetaVersionLogo from "../../logo/betaVersion";
import "./privacyPolicy.scss";

function PrivacyPolicy() {
  return (
    <>
      <div className="Privacy_page">
        <Box
          sx={{
            width: {
              xs: "60%", // Small screens
              lg: "14%", // Large screens
            },
            height: {
              xs: "30%", // Small screens
              lg: "30%", // Large screens
            },
          }}
          className="Box"
        >
          <BetaVersionLogo />
          {/* <img
                        src={logo}
                        alt="logo"
                    /> */}
        </Box>
        <div className="Content">
          <p>
            <strong>
              Our Privacy Policy has been updated and is effective as of July 5,
              2024 (the “Effective Date”).
            </strong>
          </p>
          <p>
            This privacy policy (“<strong>Privacy Policy</strong>”) applies to
            our mobile applications (“<strong>App</strong>”), owned an operated
            by D8R, Inc. (Collectively “<strong>D8R</strong>”, “
            <strong>we</strong>”, “<strong>our</strong>”, or “
            <strong>us</strong>”). This Privacy Note describes how and why we
            might collect, store, and use your information when you use our
            services (“<strong>Services</strong>”), such as when you:
          </p>
          <ul>
            <li>
              Download and use our App, or any other application of ours that
              links to this Private Policy;
            </li>
            <li>
              Engage with us in other related ways, including any communication
              with us.
            </li>
          </ul>
          <p>
            If you have any questions or concerns regarding this Privacy Policy,
            please contact us at info@d8r.me.
          </p>
          <ol>
            <li>
              <strong>
                PERSONAL, SENSITIVE, AND NON-PERSONAL DATA WE COLLECT AND HOW WE
                USE AND DISCLOSE IT
              </strong>
            </li>
          </ol>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Service, or otherwise when you
            contact us.
          </p>
          <p>
            The personal information that we collect depends on the context of
            your interactions with us and the Services, and the choices you
            make. The personal information we collect may include the following:
          </p>
          <ul>
            <li>Names,</li>
            <li>Phone Numbers,</li>
            <li>Email Addresses,</li>
            <li>Usernames,</li>
            <li>Passwords,</li>
            <li>Contact Preferences,</li>
            <li>Contact or Authentication date.</li>
          </ul>
          <p>
            We allow you to post photos and videos on the App. Applicable laws
            and regulations may vary with respect to whether such materials are
            considered to be biometric information. For clarity, we do not
            create a record of facial geometry from your photos or videos and do
            not use your photos and videos for purposes of facial recognition,
            facial surveillance, or otherwise as a biometric identifier.
          </p>
          <p>
            When necessary, we collect sensitive information, with your consent,
            that you voluntarily provided to us based on your interactions and
            use of Services. We process the following categories of sensitive
            information:
          </p>
          <ul>
            <li>Data about a person’s sexual orientation,</li>
            <li>Information revealing race of ethnic origin,</li>
            <li>Information revealing political opinions,</li>
            <li>Information revealing religious or philosophical beliefs,</li>
            <li>Education data,</li>
            <li>Health Data,</li>
            <li>Financial Data,</li>
            <li>Precise Geolocation.</li>
          </ul>
          <p>
            Sensitive information may be used, or disclosed to a service
            provider or contractor, for additional, specified purposes. You have
            the right to limit the use or disclosure of your sensitive
            information by requesting it at info@d8r.me.
          </p>
          <p>
            If you use our App, we also may collect the following information if
            you choose to provide us with access or permission to:
          </p>
          <ul>
            <li>
              Geolocation Information. We may request access or permission to
              track location-based information from your mobile device, either
              continuously or while you are using our App, to provide certain
              location-based Services. We may collect this using different types
              of technologies, including GPS and Wi-Fi. If you wish to change
              our access or permissions, you may do so in your device’s
              settings;
            </li>
            <li>
              Mobile Device Access. We may request access or permission to
              certain features from your mobile device, including your mobile
              device’s calendar, camera, microphone, and other features. If you
              with to change our access or permissions, you may do so in your
              device’s settings;
            </li>
            <li>
              Push notifications. We may request to send you push notifications
              regarding your account or certain features of the App. If you wish
              to opt out from receiving these types of communications, you may
              turn them off in your device’s settings.
            </li>
          </ul>
          <p>
            We use embedded scripts and the tools provided in Software
            Development Kits (“SDKs”) to collect data through mobile devices
            such as the hardware model, operating system and version,
            identification numbers assigned to your mobile device, such as the
            ID for Advertising (IDFA) on Apple devices, and the Advertising ID
            on Android devices, mobile network information, and App usage
            information. SDKs are software packages that contain a set of tools
            that can be used to help build applications and implement new
            features in the existing App including those that collect
            information from your device.
          </p>
          <ol start={2}>
            <li>
              <strong>HOW DO WE PROCESS YOUR INFORMATION?</strong>
            </li>
          </ol>
          <p>
            We process your personal information for a variety of reasons,
            depending on how you interact with our App, including, but not
            limited to:
          </p>
          <ul>
            <li>
              To facilitate account creation and authentication and otherwise
              manage user accounts. We may process your information so you can
              create and log in to your account, as well as keep your account
              updated and in working order.
            </li>
            <li>
              To deliver and facilitate delivery of services to the user. We may
              process your information to provide you with the requested
              services.
            </li>
            <li>
              To enable user-to-user communications. We will process your
              information that allow for communication with another user.
            </li>
          </ul>
          <p>
            We use third party analytics providers to enhance your experience
            with our App. All of the information collected automatically by
            analytical tools allow us to personalize your user experience and to
            monitor and improve the effectiveness of the App.
          </p>
          <ol start={3}>
            <li>
              <strong>
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </strong>
            </li>
          </ol>
          <p>
            When you share your personal information (for example, by posting
            photos, and profile specifications) or otherwise interact with
            public areas of the App, such personal information, voluntarily
            provided and consented by you, may be viewed by all users, and may
            be publicly made available outside the Services in perpetuity.
            Similarly, other users will be able to view descriptions of your
            activity, communicate with you within our App, and view your
            profile.
          </p>
          <ol start={4}>
            <li>
              <strong>SECURITY OF YOUR PERSONAL DATA</strong>
            </li>
          </ol>
          <p>
            Although we cannot guarantee that there will never be a security
            problem, we have in place commercially reasonable security measures
            to guard against the loss or misuse of personal data collected
            through the App. However, we cannot guarantee or warrant the
            security of any data you disclose or transmit to us, or that we
            transmit to you, or guarantee that it will be free from unauthorized
            access by third parties. In the event that we are required by law to
            inform you of a breach of your personal data, we may notify you
            electronically, in writing, or by telephone, if permitted to do so
            by applicable law. You use the App and provide us with data at your
            own initiative and risk.
          </p>
          <ol start={5}>
            <li>
              <strong>RETENTION OF YOUR PERSONAL DATA</strong>
            </li>
          </ol>
          <p>
            For personal data that we collect and process, we typically keep
            such personal data for as long as necessary to fulfill the purposes
            for which it was collected or for which you subsequently authorize
            it and otherwise to carry out the purposes described in this Privacy
            Notes, and as otherwise permitted by applicable law. Data that has
            been anonymized and aggregated is not personal data, and we will
            keep such data in accordance with our retention policy.
          </p>
          <ol start={6}>
            <li>
              <strong>
                SPECIFIC PRIVACY RIGHTS FOR RESIDENTS OF CALIFORNIA
              </strong>
            </li>
          </ol>
          <p>
            Alongside with every right mentioned above, California Civil Code
            Section 1798.83, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request to us at info@d8r.me.
          </p>
          <p>
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law.
          </p>
          <p>
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. We will only use personal information
            provided in your request to verify your identity or authority to
            make the request. To the extent possible, we will avoid requesting
            additional information from you for the purposes of verification.
          </p>
          <ol start={7}>
            <li>
              <strong>CHANGES TO OUR PRIVACY POLICY</strong>
            </li>
          </ol>
          <p>
            To the extent allowed by applicable law, D8R reserves the right to
            change our Privacy Policy at any time without prior notice. D8R will
            notify of material changes by emailing you the changed or modified
            Privacy Policy by revising the “Last Update” date at the top of this
            Privacy Policy. Any changes will be effective immediately upon the
            position of the revised Privacy Policy unless otherwise specified.
            Your continue use of the App after the effective date of the revised
            Privacy Policy will constitute your consent to those changes to the
            fullest extent allowed by applicable law.
          </p>
          <p>July 5, 2024</p>{" "}
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
