// reducers/StepsReducer.ts
export const UPDATE_DRAWER = 'UPDATE_DRAWER';

// Define the state interface
export interface DrawerState { // Export the interface
    drawer: false;
}

// Define the initial state
const initialState: DrawerState = {
    drawer: false,
};

export const updateDrawer = (data: any) => ({
    type: UPDATE_DRAWER,
    payload: data,
});

// Define the reducer
const DrawerReducer = (
    state = initialState,
    action: { type: string; payload: any }
): DrawerState => {
    switch (action.type) {
        case UPDATE_DRAWER:
            // console.log(action.payload)
            return {
                ...state,
                drawer: action.payload,
            };
        default:
            return state;
    }
};



export default DrawerReducer;
