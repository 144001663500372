import React from 'react';
import './component.scss'; // Import the CSS file

interface CustomStepperProps {
  activeStep: number;
  totalSteps: number;
}

const CustomStepper: React.FC<CustomStepperProps> = ({ activeStep, totalSteps }) => {
  return (
    <div className="stepper-container">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`stepper-line ${index === activeStep ? 'active' : ''}`}
        />
      ))}
    </div>
  );
};

export default CustomStepper;
