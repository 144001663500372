// reducers/StepsReducer.ts
export const UPDATE_STATE = 'UPDATE_STATE';

// Define the state interface
export interface UserState { // Export the interface
    UserInfo: any;
}

// Define the initial state
const initialState: UserState = {
    UserInfo: {},
};

export const updateUsers = (data: any) => ({
    type: UPDATE_STATE,
    payload: data,
});

// Define the reducer
const UserReducer = (
    state = initialState,
    action: { type: string; payload: any }
): UserState => {
    switch (action.type) {
        case UPDATE_STATE:
            // console.log(action.payload)
            return {
                ...state,
                UserInfo: action.payload,
            };
        default:
            return state;
    }
};



export default UserReducer;
