// src/theme.ts
import { createTheme, responsiveFontSizes } from '@mui/material/styles';


// Define the base theme
const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: 14, // Base font size for body text
    h1: {
      fontSize: '1.528rem', // Base font size for h1
      fontWeight: "600",
      '@media (max-width:600px)': {
        fontSize: '1.5rem', // Responsive font size for small screens
      },
    },
    h2: {
      fontSize: '0.85rem',
      fontWeight: "600",
      paddingLeft: '5px',
      '@media (max-width:600px)': {
        fontSize: '1.25rem', // Responsive font size for small screens
      },
    },
    h3: {
      fontSize: '0.95rem',
      fontWeight: "500", // Base font size for h3
      '@media (max-width:600px)': {
        fontSize: '1rem', // Responsive font size for small screens
      },
    },
    h4: {
      display: 'inline',
      fontWeight: "500",
      fontSize: '1.1rem',
      '@media (max-width:600px)': {
        fontSize: '1rem', // Responsive font size for small screens
      },
    },
    h5: {
      display: 'inline',
      fontWeight: "500",
      fontSize: '0.7rem',
      '@media (max-width:600px)': {
        fontSize: '0.7rem', // Responsive font size for small screens
      },
    },

    // button: {
    //   fontSize: '0.875rem', // Base font size for button text
    //   '@media (max-width:600px)': {
    //     fontSize: '0.75rem', // Responsive font size for small screens
    //   },
    // },
  },
});

// Apply responsive font sizes
const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;
